import { chakra, HTMLChakraProps } from '@chakra-ui/react';

import * as Icons16 from './Icons16';
import * as Icons24 from './Icons24';
import * as Icons32 from './Icons32';
import { isUndefined } from 'lodash';

export type IconName =
  | 'AlertIcon'
  | 'InformationIcon'
  | 'HelpIcon'
  | 'TickInCircleIcon'
  | 'CrossIcon'
  | 'NoDealIcon'
  | 'WarningIcon'
  | 'TickIcon'
  | 'PlusIcon'
  | 'CloseIcon'
  | 'MinusIcon'
  | 'MenuIcon'
  | 'FilterIcon'
  | 'FilterIcon'
  | 'ArrowRightIcon'
  | 'ArrowLeftIcon'
  | 'ReplyIcon'
  | 'ArrowUpDownIcon'
  | 'RepeatIcon'
  | 'DownloadIcon'
  | 'UploadIcon'
  | 'InboxIcon'
  | 'ExportIcon'
  | 'ExternalLinkIcon'
  | 'LinkIcon'
  | 'CopyIcon'
  | 'RubbishIcon'
  | 'SettingsIcon'
  | 'PrintIcon'
  | 'CalendarIcon'
  | 'ClipboardIcon'
  | 'ArchiveIcon'
  | 'ChatIcon'
  | 'NotificationIcon'
  | 'BoxIcon'
  | 'UserIcon'
  | 'UsersIcon'
  | 'UserGroupIcon'
  | 'AddUserIcon'
  | 'UserInCircleIcon'
  | 'StarIcon'
  | 'StarSparklesIcon'
  | 'HideIcon'
  | 'ShowIcon'
  | 'LockOnIcon'
  | 'LockOffIcon'
  | 'EditBoxIcon'
  | 'EditIcon'
  | 'SearchIcon'
  | 'LabelIcon'
  | 'CartIcon'
  | 'DeliveryIcon'
  | 'GiftIcon'
  | 'BirthdayIcon'
  | 'ScalesIcon'
  | 'HeartIcon'
  | 'DesktopIcon'
  | 'MobileIcon'
  | 'CallIcon'
  | 'MailIcon'
  | 'HomeIcon'
  | 'LocationIcon'
  | 'LightningIcon'
  | 'PhotoIcon'
  | 'CameraOnIcon'
  | 'CameraOffIcon'
  | 'VideoOnIcon'
  | 'VideoOffIcon'
  | 'MicrophoneIcon'
  | 'ShareIcon'
  | 'MoneyIcon'
  | 'CardIcon'
  | 'IdentificationIcon'
  | 'ShieldIcon'
  | 'TemplateIcon'
  | 'CalculatorIcon'
  | 'MoreIcon'
  | 'NewsIcon'
  | 'BatchboxIcon'
  | 'SunIcon'
  | 'ClipboardCheckedIcon'
  | 'EarthIcon'
  | 'SmileyIcon'
  | 'CrossFillIcon'
  | 'TickInCircleFillIcon'
  | 'AlertFillIcon'
  | 'WarningFillIcon';
export interface SizedIconProps extends HTMLChakraProps<'svg'> {
  name: string;
}

export const SizedIcon = ({
  name: propName,
  ...props
}: SizedIconProps): JSX.Element => {
  const { width } = props;
  const name: IconName = propName as IconName;
  let Icon = Icons32[name];

  if (isUndefined(width) || width < '24px') Icon = Icons16[name];
  else if (width >= '24px' && width < '32px') Icon = Icons24[name];

  return <Icon {...props} />;
};

export const AddUserIcon = (props: HTMLChakraProps<'svg'>): JSX.Element => {
  return <SizedIcon name="AddUserIcon" {...props} />;
};

export const AlertIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="AlertIcon" {...props} />;
};

export const AlertFillIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="AlertFillIcon" {...props} />;
};

export const ArchiveIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="ArchiveIcon" {...props} />;
};

export const ArrowLeftIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="ArrowLeftIcon" {...props} />;
};

export const ArrowRightIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="ArrowRightIcon" {...props} />;
};

export const ArrowUpDownIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="ArrowUpDownIcon" {...props} />;
};

export const BatchboxIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="BatchboxIcon" {...props} />;
};

export const BirthdayIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="BirthdayIcon" {...props} />;
};

export const BlankIcon = (props: HTMLChakraProps<'svg'>) => {
  return (
    <chakra.svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
};

export const BoxIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="BoxIcon" {...props} />;
};

export const BoxGroupIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="BoxGroupIcon" {...props} />;
};

export const CalculatorIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="CalculatorIcon" {...props} />;
};

export const CalendarIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="CalendarIcon" {...props} />;
};

export const CallIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="CallIcon" {...props} />;
};

export const CameraOffIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="CameraOffIcon" {...props} />;
};

export const CameraOnIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="CameraOnIcon" {...props} />;
};

export const CardIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="CardIcon" {...props} />;
};

export const CaretDownIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10 4H2l4 4 4-4Z" fill="currentColor" />
  </chakra.svg>
);

export const CaretUpIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10 8H2L6 4L10 8Z" fill="currentColor" />
  </chakra.svg>
);

export const CaretRightIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4 2V10L8 6L4 2Z" fill="currentColor" />
  </chakra.svg>
);

export const CaretLeftIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 2V10L4 6L8 2Z" fill="currentColor" />
  </chakra.svg>
);

export const CartIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="CartIcon" {...props} />;
};

export const ChatIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="ChatIcon" {...props} />;
};

export const ChevronDownIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.5 5L8 11L13.5 5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </chakra.svg>
);

export const ChevronLeftIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 13.5L5 8L11 2.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </chakra.svg>
);

export const ChevronRightIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 13.5L11 8L5 2.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </chakra.svg>
);

export const ChevronUpIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.5 11L8 5L13.5 11"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </chakra.svg>
);

export const ClipboardIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="ClipboardIcon" {...props} />;
};

export const ClipboardCheckedIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="ClipboardCheckedIcon" {...props} />;
};

export const CloseIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="CloseIcon" {...props} />;
};

export const CopyIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="CopyIcon" {...props} />;
};

export const CrossIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="CrossIcon" {...props} />;
};

export const CrossFillIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="CrossFillIcon" {...props} />;
};

export const DeliveryIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="DeliveryIcon" {...props} />;
};

export const DesktopIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="DesktopIcon" {...props} />;
};

export const DownloadIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="DownloadIcon" {...props} />;
};

export const EarthIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="EarthIcon" {...props} />;
};

export const EditIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="EditIcon" {...props} />;
};

export const EditBoxIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="EditBoxIcon" {...props} />;
};

export const ExportIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="ExportIcon" {...props} />;
};

export const ExternalLinkIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="ExternalLinkIcon" {...props} />;
};

export const FilterIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="FilterIcon" {...props} />;
};

export const GiftIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="GiftIcon" {...props} />;
};

export const HeartIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="HeartIcon" {...props} />;
};

export const HelpIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="HelpIcon" {...props} />;
};

export const HospoconnectLogoIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="HospoconnectLogoIcon" {...props} />;
};

export const HideIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="HideIcon" {...props} />;
};

export const HomeIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="HomeIcon" {...props} />;
};

export const IdentificationIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="IdentificationIcon" {...props} />;
};

export const InboxIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="InboxIcon" {...props} />;
};

export const InformationIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="InformationIcon" {...props} />;
};

export const LabelIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="LabelIcon" {...props} />;
};

export const LightningIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="LightningIcon" {...props} />;
};

export const LinkIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="LinkIcon" {...props} />;
};

export const LocationIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="LocationIcon" {...props} />;
};

export const LockOffIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="LockOffIcon" {...props} />;
};

export const LockOnIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="LockOnIcon" {...props} />;
};

export const MailIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="MailIcon" {...props} />;
};

export const MenuIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="MenuIcon" {...props} />;
};

export const MicrophoneIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="MicrophoneIcon" {...props} />;
};

export const MinusIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="MinusIcon" {...props} />;
};

export const MobileIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="MobileIcon" {...props} />;
};

export const MoneyIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="MoneyIcon" {...props} />;
};

export const MoreIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="MoreIcon" {...props} />;
};

export const NewsIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="NewsIcon" {...props} />;
};

export const NoDealIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="NoDealIcon" {...props} />;
};

export const NotificationIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="NotificationIcon" {...props} />;
};

export const PhotoIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="PhotoIcon" {...props} />;
};

export const PlaceholderIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="PlaceholderIcon" {...props} />;
};

export const PlusIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="PlusIcon" {...props} />;
};

export const PrintIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="PrintIcon" {...props} />;
};

export const RepeatIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="RepeatIcon" {...props} />;
};

export const ReplyIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="ReplyIcon" {...props} />;
};

export const RubbishIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="RubbishIcon" {...props} />;
};

export const ScalesIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="ScalesIcon" {...props} />;
};

export const SearchIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="SearchIcon" {...props} />;
};

export const SettingsIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="SettingsIcon" {...props} />;
};

export const ShareIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="ShareIcon" {...props} />;
};

export const ShieldIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="ShieldIcon" {...props} />;
};

export const ShowIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="ShowIcon" {...props} />;
};

export const SmileyIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="SmileyIcon" {...props} />;
};

export const StarIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="StarIcon" {...props} />;
};

export const StarSparklesIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="StarSparklesIcon" {...props} />;
};

export const SunIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="SunIcon" {...props} />;
};

export const TemplateIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="TemplateIcon" {...props} />;
};

export const TickIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="TickIcon" {...props} />;
};

export const TickInCircleIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="TickInCircleIcon" {...props} />;
};

export const TickInCircleFillIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="TickInCircleFillIcon" {...props} />;
};

export const UploadIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="UploadIcon" {...props} />;
};

export const UserIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="UserIcon" {...props} />;
};

export const UserInCircleIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="UserInCircleIcon" {...props} />;
};

export const UserGroupIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="UserGroupIcon" {...props} />;
};

export const UsersIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="UsersIcon" {...props} />;
};

export const VideoOffIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="VideoOffIcon" {...props} />;
};

export const VideoOnIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="VideoOnIcon" {...props} />;
};

export const WarningIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="WarningIcon" {...props} />;
};

export const WarningFillIcon = (props: HTMLChakraProps<'svg'>) => {
  return <SizedIcon name="WarningFillIcon" {...props} />;
};
