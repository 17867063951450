import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useMixpanel } from 'react-mixpanel-browser';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Modal,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import useAPI from '../../../hooks/useAPI';
import { xClientHeaders } from '../../../utils';
import { useAddOrder } from '../../../contexts/addOrder';
import { useErrorToast, useSuccessToast } from '../../../components/toast';
import { useOrders, useCurrentUser } from '../../../hooks/useStores';
import AddOrderModalContent from '../../../components/AddOrderModal/AddOrderModalContent';
import { StandingOrder } from '../../../stores/standingOrders/standingOrder';

const AddStandingOrderModal = ({
  standingOrder,
  deliveryDate,
}: {
  standingOrder: StandingOrder;
  deliveryDate: string;
}) => {
  const mixpanel = useMixpanel();
  const { getOrders } = useOrders();
  const { order, setOrderDataFromStandingOrder } = useAddOrder();
  const { authToken, isBuyer } = useCurrentUser();
  const history = useHistory();
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const {
    isOpen: alertIsOpen,
    onOpen: alertOnOpen,
    onClose: alertOnClose,
  } = useDisclosure();
  const cancelAlertRef = useRef(null);

  const token = `Bearer ${authToken}`;

  useEffect(() => {
    setOrderDataFromStandingOrder(standingOrder, deliveryDate);
  }, [standingOrder]);

  const [createOrder, isLoading] = useAPI({
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      ...xClientHeaders,
    },
  });

  const sendOrder = () => {
    const formData = order.formState();
    formData.order.source = 'standing_order';
    createOrder('/v4/orders', {
      body: JSON.stringify(formData),
    })
      .then((data: any) => {
        mixpanel.track('Create and edit Standing Order -> Save');
        successToast({
          description: `Instance of Standing Order added for ${order.customer?.name}`,
        });
        history.push(`/orders/${data.id}`);
        getOrders(
          () => {},
          () => {},
          isBuyer ? 'outgoing' : 'incoming',
        );
      })
      .catch(() => {
        errorToast({
          description: 'Something went wrong and your order failed to save.',
        });
      });
  };

  const handleClose = () => {
    alertOnOpen();
  };

  const confirmClose = () => {
    // There are issues with the state tree when trying to replace the order with a new one
    // So we just do a full page load here to avoid the problem :eyeroll:
    window.location.href = `/standing-orders/${standingOrder.id}`;
  };

  return (
    <>
      <AlertDialog
        isOpen={alertIsOpen}
        onClose={alertOnClose}
        leastDestructiveRef={cancelAlertRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent marginTop="7.5rem">
            <AlertDialogHeader fontSize="lg" fontWeight="700">
              Quit order?
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelAlertRef} onClick={alertOnClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmClose} ml={3}>
                Yes, quit order
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal
        isOpen={true}
        onClose={handleClose}
        size="xl"
        closeOnOverlayClick={false}
        autoFocus={false}
      >
        <ModalOverlay />
        <AddOrderModalContent
          submitOrder={sendOrder}
          initialScreen={'review-order'}
          isSubmitting={isLoading}
          disabledFields={['customer', 'supplier', 'deliveryDate']}
        />
      </Modal>
    </>
  );
};

export default observer(AddStandingOrderModal);
