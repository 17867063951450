import { makeObservable, observable, action, computed } from 'mobx';
import {
  Instance
} from 'mobx-state-tree';

import { capitalizeString } from '../../utils';
import { CustomerGroup } from '../../models/CustomerGroup';
import { DeliveryRules } from '../../models/DeliveryRules';
import { CustomerStore } from './customerStore';

export interface BaseCustomerProps {
  id: number;
  name: string;
  logo_url?: string;
  is_unlinked: boolean;
}

interface CustomerCustomerGroupProps {
  id: number;
  customer_group_id: number;
  customer_group_name: string;
  customer_group_set_id: number;
  customer_group_set_name: string;
  customer_group_delivery_rules_enabled: boolean;
  customer_group_delivery_rules: Instance<typeof DeliveryRules> | null;
}

export class CustomerCustomerGroup {
  @observable customer: Customer;
  @observable customerGroupId: number | '';
  @observable customerGroupName: string;
  @observable customerGroupSetName: string | null;
  @observable customerGroupDeliveryRulesEnabled: boolean = false;
  @observable customerGroupDeliveryRules: Instance<typeof DeliveryRules> | null = null;

  constructor(customer: Customer, props: CustomerCustomerGroupProps) {
    this.customer = customer;

    const {
      customer_group_id,
      customer_group_name,
      customer_group_delivery_rules_enabled,
      customer_group_delivery_rules,
      customer_group_set_name
    } = props;

    this.customerGroupId = customer_group_id;
    this.customerGroupName = customer_group_name;
    this.customerGroupSetName = customer_group_set_name;
    this.customerGroupDeliveryRulesEnabled = customer_group_delivery_rules_enabled;
    this.customerGroupDeliveryRules = customer_group_delivery_rules;

    makeObservable(this);
  }

  @action set = (newGroup: CustomerGroup) => {
    this.customerGroupId = newGroup.id;
    this.customerGroupName = newGroup.name;
    this.customerGroupSetName = newGroup.customer_group_set_name;
    this.customerGroupDeliveryRulesEnabled = newGroup.delivery_rules_enabled;
    this.customerGroupDeliveryRules = newGroup.delivery_rules;
  };

  @action unset = () => {
    this.customerGroupId = '';
    this.customerGroupName = '';
    this.customerGroupSetName = '';
    this.customerGroupDeliveryRulesEnabled = false;
    this.customerGroupDeliveryRules = null;
  };
}

export interface ConnectorFieldsProps {
  default_address_code?: string;
}

export interface CustomerProps extends BaseCustomerProps {
  about: string;
  email: string;
  phone?: string;
  website?: string;
  address?: string;
  default_address?: string;
  default_instructions?: string;
  notes?: string;
  can_edit_company_fields?: boolean;
  is_address_supplier_provided?: boolean;
  customer_groups?: CustomerCustomerGroupProps[];
  connector_fields?: ConnectorFieldsProps;
}

export class Customer {
  id: number;
  @observable store: CustomerStore;

  logo_url?: string;
  about?: string;
  address?: string;
  is_unlinked?: boolean;
  can_edit_company_fields?: boolean;
  is_address_supplier_provided?: boolean;

  @observable name?: string;
  @observable notes?: string;
  @observable email?: string;
  @observable phone?: string;
  @observable website?: string;
  @observable default_address?: string;
  @observable default_instructions?: string;
  @observable connectorFieldsDefaultAddressCode?: string;

  @observable customerGroupsByGroupSetId: {
    [id: number]: CustomerCustomerGroup;
  } = {};
  @observable formCustomerGroupsByGroupSetId: {
    [id: number]: CustomerCustomerGroup;
  } = {};

  @observable formName?: string;
  @observable formNotes?: string;
  @observable formEmail?: string;
  @observable formPhone?: string;
  @observable formWebsite?: string;
  @observable formAddress?: string;
  @observable formConnectorFieldsDefaultAddressCode?: string;

  @observable isChecked: boolean = false;
  @observable isCurrentCustomer: boolean = false;

  @observable isFullyLoaded: boolean = false;

  constructor(store: CustomerStore, props: BaseCustomerProps | CustomerProps) {
    this.store = store;
    const { id, name, logo_url, is_unlinked } = props;

    this.id = id;
    this.name = name;
    this.logo_url = logo_url;
    this.is_unlinked = is_unlinked;

    const isCustomerProps = 'email' in props;
    if (isCustomerProps) {
      this.addData(props);
    }

    makeObservable(this);
  }

  @computed get pricingTierGroup() {
    return Object.values(this.formCustomerGroupsByGroupSetId).find((cg) => cg.customerGroupSetName == 'Pricing Tiers');
  }

  @computed get customerCustomerGroupWithDeliveryRules() {
    return Object.values(this.formCustomerGroupsByGroupSetId).find((cg) => cg.customerGroupDeliveryRulesEnabled);
  }

  @computed get formState() {
    return {
      customer: {
        notes: this.formNotes || '',
        supplier_provided_address: this.formAddress,
        connector_fields: {
          default_address_code: this.formConnectorFieldsDefaultAddressCode
        }
      },
      company: {
        name: this.formName || '',
        email: this.formEmail || '',
        phone: this.formPhone || '',
        website: this.formWebsite || '',
        customer_group_ids:
          this.formCustomerGroupsByGroupSetId &&
          Object.values(this.formCustomerGroupsByGroupSetId).map(
            (group) => group.customerGroupId,
          ),
      },
    };
  }

  @action toggleChecked = () => {
    this.isChecked = !this.isChecked;
  };

  @action setIsCurrentCustomer = (value: boolean) => {
    this.isCurrentCustomer = value;
  };

  @action setName = (value: string) => (this.name = value);
  @action setFormName = (value: string) => (this.formName = value);

  @action setNotes = (value: string) => (this.notes = value);
  @action setFormNotes = (value: string) => (this.formNotes = value);

  @action setEmail = (value: string) => (this.email = value);
  @action setFormEmail = (value: string) => (this.formEmail = value);

  @action setPhone = (value: string) => (this.phone = value);
  @action setFormPhone = (value: string) => (this.formPhone = value);

  @action setWebsite = (value: string) => (this.website = value);
  @action setFormWebsite = (value: string) => (this.formWebsite = value);

  @action setFormAddress = (value: string) => (this.formAddress = value);

  @action setFormConnectorFieldsDefaultAddressCode = (value: string) => (this.formConnectorFieldsDefaultAddressCode = value);

  @action addGroup = (props: CustomerCustomerGroupProps) => {
    this.formCustomerGroupsByGroupSetId[props.customer_group_set_id] =
      new CustomerCustomerGroup(this, props);
  };

  @action addData = (props: CustomerProps) => {
    const {
      name,
      about,
      email,
      phone,
      website,
      address,
      notes,
      is_unlinked,
      can_edit_company_fields,
      is_address_supplier_provided,
      customer_groups,
      default_address,
      default_instructions,
      connector_fields
    } = props;
    this.name = name;
    this.about = about;
    this.email = email;
    this.phone = phone;
    this.website = website;
    this.address = address;
    this.notes = notes;
    this.default_address = default_address;
    this.default_instructions = default_instructions;
    this.is_unlinked = is_unlinked;
    this.can_edit_company_fields = can_edit_company_fields;
    this.is_address_supplier_provided = is_address_supplier_provided;
    this.isFullyLoaded = true;

    this.customerGroupsByGroupSetId = {};
    this.formCustomerGroupsByGroupSetId = {};
    customer_groups?.forEach((cg: any) => {
      this.customerGroupsByGroupSetId[cg.customer_group_set_id] =
        new CustomerCustomerGroup(this, cg);
      this.formCustomerGroupsByGroupSetId[cg.customer_group_set_id] =
        new CustomerCustomerGroup(this, cg);
    });

    this.formNotes = notes;
    this.formName = name;
    this.formEmail = email;
    this.formAddress = default_address;
    this.formPhone = phone;
    this.formWebsite = website;

    if (connector_fields) {
      this.connectorFieldsDefaultAddressCode = connector_fields.default_address_code;
      this.formConnectorFieldsDefaultAddressCode = connector_fields.default_address_code;
    }
  };
}
