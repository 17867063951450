import { useEffect } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import NoMobilePage from '../pages/NoMobilePage/NoMobilePage';
import LoginPage from '../pages/LoginPage/LoginPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage';
import OrdersPage from '../pages/OrdersPage/OrdersPage';
import ProductsPage from '../pages/ProductsPage/ProductsPage';
import CustomersPage from '../pages/CustomersPage/CustomersPage';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage';
import RootRoute from './Routes/RootRoute';
import AppRoute from './Routes/AppRoute';
import { Route, Switch } from 'react-router-dom';
import NotificationHandler from '../components/NotificationHandler/NotificationHandler';
import TermsAgreement from '../components/TermsAgreement/TermsAgreement';
import { useAuth } from '../contexts/auth';

import { CustomView, isDesktop, isTablet, isMobile } from 'react-device-detect';
import SourceCategoriesPage from '../pages/SourceCategoriesPage/SourceCategoriesPage';
import CustomerGroupsPage from '../pages/CustomerGroupsPage/CustomerGroupsPage';
import ProductGroupsPage from '../pages/ProductGroupsPage/ProductGroupsPage';
import IntegrationsPage from '../pages/IntegrationsPage/IntegrationsPage';
import SyncErrorsPage from '../pages/IntegrationsPage/Integrations/Products/SyncErrorsPage';
import DeliveryDaysPage from '../pages/DeliveryDaysPage/DeliveryDaysPage';
import PricingTiersPage from '../pages/PricingTiersPage/PricingTiersPage';
import ProfilePage from '../pages/ProfilePage/ProfilePage';
import StandingOrdersPage from '../pages/StandingOrdersPage/StandingOrdersPage';
import RegisterCtaPage from '../pages/RegisterCtaPage/RegisterCtaPage';

const App = (): JSX.Element => {
  const mixpanel = useMixpanel();
  const { user } = useAuth();
  const isAuthenticated = user ? true : false;

  useEffect(() => {
    if (isAuthenticated) {
      mixpanel.identify(user.id);
      mixpanel.people.set({
        $name: user.fullname,
      });
    }
  }, [user]);

  return (
    <>
      <CustomView condition={isMobile && window.innerWidth < 1024}>
        <Switch>
          <AppRoute
            isGuestRoute={true}
            isAuthenticated={isAuthenticated}
            exact
            path="/forgot-password"
            component={ForgotPasswordPage}
          />
          <AppRoute
            isGuestRoute={true}
            isAuthenticated={isAuthenticated}
            exact
            path="/reset-password"
            component={ResetPasswordPage}
          />
          <AppRoute
            isGuestRoute={true}
            isAuthenticated={isAuthenticated}
            path="/register-cta"
            component={RegisterCtaPage}
          />
          <AppRoute
            isGuestRoute={true}
            isAuthenticated={isAuthenticated}
            path="/"
            component={NoMobilePage}
          />
        </Switch>
      </CustomView>
      <CustomView
        condition={isDesktop || isTablet || window.innerWidth >= 1024}
      >
        <NotificationHandler />
        <TermsAgreement />
        <Switch>
          <AppRoute
            isGuestRoute={true}
            exact
            path="/login"
            isAuthenticated={isAuthenticated}
            component={LoginPage}
          />
          <AppRoute
            isGuestRoute={true}
            exact
            path="/forgot-password"
            isAuthenticated={isAuthenticated}
            component={ForgotPasswordPage}
          />
          <AppRoute
            isGuestRoute={true}
            exact
            path="/reset-password"
            isAuthenticated={isAuthenticated}
            component={ResetPasswordPage}
          />
          <AppRoute
            isGuestRoute={true}
            isAuthenticated={isAuthenticated}
            path="/register-cta"
            component={RegisterCtaPage}
          />
          <AppRoute
            exact
            path={['/profile', '/profile/edit']}
            isAuthenticated={isAuthenticated}
            component={ProfilePage}
          />
          <AppRoute
            exact
            path={[
              '/orders',
              '/orders/new',
              '/orders/:orderId',
              '/orders/:orderId/edit',
            ]}
            isAuthenticated={isAuthenticated}
            component={OrdersPage}
          />
          <AppRoute
            exact
            path={[
              '/products',
              '/products/new',
              '/products/:productId',
              '/products/:productId/edit',
            ]}
            userTypeWhitelist={'supplier'}
            isAuthenticated={isAuthenticated}
            component={ProductsPage}
          />

          <AppRoute
            exact
            path={[
              '/customers',
              '/customers/new',
              '/customers/:customerId',
              '/customers/:customerId/edit',
            ]}
            userTypeWhitelist={'supplier'}
            isAuthenticated={isAuthenticated}
            component={CustomersPage}
          />

          <AppRoute
            exact
            path={[
              '/standing-orders',
              '/standing-orders/new',
              '/standing-orders/:standingOrderId',
              '/standing-orders/:standingOrderId/edit',
              '/standing-orders/:standingOrderId/create-now/:deliveryDate',
            ]}
            isAuthenticated={isAuthenticated}
            component={StandingOrdersPage}
          />
          <AppRoute
            exact
            path={[
              '/customer-group-sets',
              '/customer-group-sets/new',
              '/customer-group-sets/:groupSetId/edit',
              '/customer-group-sets/:groupSetId/customer-groups/new',
              '/customer-group-sets/:groupSetId/customer-groups/unassigned',
              '/customer-group-sets/:groupSetId/customer-groups/:groupId',
              '/customer-group-sets/:groupSetId/customer-groups/:groupId/edit',
            ]}
            userTypeWhitelist={'supplier'}
            isAuthenticated={isAuthenticated}
            component={CustomerGroupsPage}
          />
          <AppRoute
            exact
            path={[
              '/product-group-sets',
              '/product-group-sets/new',
              '/product-group-sets/:groupSetId/edit',
              '/product-group-sets/:groupSetId/product-groups/new',
              '/product-group-sets/:groupSetId/product-groups/unassigned',
              '/product-group-sets/:groupSetId/product-groups/:groupId',
              '/product-group-sets/:groupSetId/product-groups/:groupId/edit',
            ]}
            userTypeWhitelist={'supplier'}
            isAuthenticated={isAuthenticated}
            component={ProductGroupsPage}
          />
          <AppRoute
            exact
            path={[
              '/source-categories',
              '/source-categories/new',
              '/source-categories/unassigned',
              '/source-categories/:sourceCategoryId',
              '/source-categories/:sourceCategoryId/edit',
            ]}
            userTypeWhitelist={'supplier'}
            isAuthenticated={isAuthenticated}
            component={SourceCategoriesPage}
          />
          <AppRoute
            exact
            path="/settings/integrations"
            userTypeWhitelist={'supplier'}
            isAuthenticated={isAuthenticated}
            component={IntegrationsPage}
          />
          <AppRoute
            exact
            path="/settings/integrations/sync-errors"
            userTypeWhitelist={'supplier'}
            isAuthenticated={isAuthenticated}
            component={SyncErrorsPage}
          />
          <AppRoute
            exact
            path="/settings/delivery-days"
            userTypeWhitelist={'supplier'}
            isAuthenticated={isAuthenticated}
            component={DeliveryDaysPage}
          />
          <AppRoute
            exact
            path={[
              '/pricing-tiers',
              '/pricing-tiers/customer-groups/new',
              '/pricing-tiers/customer-groups/unassigned',
              '/pricing-tiers/customer-groups/:groupId',
              '/pricing-tiers/customer-groups/:groupId/edit',
            ]}
            isAuthenticated={isAuthenticated}
            component={PricingTiersPage}
          />
          <AppRoute
            exact
            path="/error"
            isAuthenticated={isAuthenticated}
            component={ErrorPage}
          />

          <RootRoute exact path="/" isAuthenticated={isAuthenticated} />

          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </CustomView>
    </>
  );
};

export default App;
