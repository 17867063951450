import { useState } from 'react';
import { observer } from 'mobx-react';
import { isAndroid } from 'react-device-detect';
import {
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure,
  MenuButtonProps,
  forwardRef,
} from '@chakra-ui/react';
import { pluralizeString } from '../../utils';
import { PrintIcon } from '../../components/Icons/Icons';
import useDocuments from '../../hooks/useDocuments';
import SelectProductModal from '../../components/SelectProductModal/SelectProductModal';
import OrdersOpenDocumentModal from './OrdersOpenDocumentModal';
import OrdersUpdateStatusModal from './OrdersUpdateStatusModal';
import FakeProgressModal from '../../components/FakeProgressModal/FakeProgressModal';
import { base64ToUrl, printInNewWindow } from '../../print';

export type OrdersPrintActionProps = {
  orderIds: number[];
  buttonText?: string | null;
  tooltipText: string;
  useIconButton: boolean;
};

const OrdersPrintAction = ({
  orderIds,
  buttonText,
  tooltipText,
  useIconButton,
}: OrdersPrintActionProps): JSX.Element => {
  const printBase64 = async (response: any) => {
    setProcessingIsDone(true);
    if (typeof window === 'undefined') {
      return '';
    }

    const data = await response.text();
    const pdfUrl = base64ToUrl(data);
    setPrintUrl(pdfUrl);
    printDialogOnOpen();
    print(pdfUrl);
  };

  const {
    showLegacyPickSheet,
    modalIsOpen,
    modalText,
    updateStatusModalIsOpen,
    setUpdateStatusModalIsOpen,
    timePerItemMs,
    processingIsDone,
    setProcessingIsDone,
    newOrderIds,
    resetState,
    onClickDocument,
    selectProductModalIsOpen,
    setSelectProductModalIsOpen,
    onSelectProduct,
    selectedProductId,
    onConfirmProduct,
    afterProcessing,
    documentTemplates,
    onClickDocumentTemplate,
  } = useDocuments('Print', 'printing', orderIds, printBase64);
  const isSingular = orderIds.length === 1;
  const [popupsBlocked, setPopupsBlocked] = useState(false);

  const {
    isOpen: printDialogIsOpen,
    onOpen: printDialogOnOpen,
    onClose: printDialogOnClose,
  } = useDisclosure();
  const [printUrl, setPrintUrl] = useState('');

  const print = (url: any) => {
    printInNewWindow({
      url,
      onPopupBlocked: () => {
        setPopupsBlocked(true);
      },
    });
  };

  const ariaLabel = 'Open Print menu';
  const MenuButtonComponent = forwardRef<MenuButtonProps, 'div'>(
    (props: MenuButtonProps, ref) => {
      if (useIconButton) {
        return (
          <MenuButton
            as={IconButton}
            aria-label={ariaLabel}
            icon={<PrintIcon />}
            variant="ghost"
            ref={ref}
          />
        );
      } else {
        return (
          <MenuButton
            as={Button}
            aria-label={ariaLabel}
            leftIcon={<PrintIcon />}
            variant="ghost"
            ref={ref}
          >
            {buttonText}
          </MenuButton>
        );
      }
    },
  );

  const showAndroidWarning = isAndroid;

  return (
    <>
      <Menu>
        <Tooltip
          label={tooltipText}
          isDisabled={modalIsOpen} // This fixes a bug where the tooltip stays open over top of the modal
        >
          <MenuButtonComponent />
        </Tooltip>
        <MenuList zIndex="dropdown">
          {documentTemplates.map((documentTemplate) => (
            <MenuItem
              key={documentTemplate.id}
              onClick={() => {
                onClickDocumentTemplate(documentTemplate);
              }}
            >
              {documentTemplate.name}
            </MenuItem>
          ))}
          {documentTemplates.length > 0 && <MenuDivider />}
          <MenuItem
            onClick={() => {
              onClickDocument('pick_sheet');
            }}
          >
            {pluralizeString('Pick sheet', orderIds.length)}
          </MenuItem>
          <MenuItem
            isDisabled={isSingular}
            onClick={() => {
              onClickDocument('pick_all_sheet');
            }}
          >
            Pick-all sheet
          </MenuItem>
          <MenuItem
            isDisabled={isSingular}
            onClick={() => {
              onClickDocument('production_sheet');
            }}
          >
            Production sheet
          </MenuItem>
          <MenuItem
            onClick={() => {
              onClickDocument('packing_slip');
            }}
          >
            {pluralizeString('Packing slip', orderIds.length)}
          </MenuItem>
          <MenuItem
            isDisabled={isSingular}
            onClick={() => {
              onClickDocument('quantity_report');
            }}
          >
            Quantity report
          </MenuItem>
          {showLegacyPickSheet && (
            <MenuItem
              onClick={() => {
                onClickDocument('legacy_pick_sheet');
              }}
            >
              {pluralizeString('Legacy Pick sheet', orderIds.length)}
            </MenuItem>
          )}
        </MenuList>
      </Menu>

      <FakeProgressModal
        mainText={modalText}
        isOpen={modalIsOpen}
        isDone={processingIsDone}
        totalItems={orderIds.length}
        timePerItemMs={timePerItemMs}
        bottomChildren={
          showAndroidWarning ? (
            <Alert status="warning" size="sm">
              <AlertIcon />
              <Box>
                <AlertTitle>Issues while using an Android device?</AlertTitle>
                <AlertDescription>
                  If printing isn't working, try another browser or use your
                  laptop / desktop computer.
                </AlertDescription>
              </Box>
            </Alert>
          ) : (
            ''
          )
        }
      />
      <OrdersUpdateStatusModal
        orderIds={newOrderIds}
        status={'processing'}
        isOpen={updateStatusModalIsOpen}
        onClose={() => {
          resetState();
          setUpdateStatusModalIsOpen(false);
        }}
      />
      <OrdersOpenDocumentModal
        isOpen={printDialogIsOpen}
        arePopupsBlocked={popupsBlocked}
        onClose={() => {
          printDialogOnClose();
          afterProcessing();
        }}
        onClick={() => print(printUrl)}
      />
      <SelectProductModal
        isOpen={selectProductModalIsOpen}
        onClose={() => setSelectProductModalIsOpen(false)}
        onSelect={onSelectProduct}
        onConfirm={onConfirmProduct}
        selectedProductId={selectedProductId}
        confirmButtonText={'Create Report'}
      />
    </>
  );
};

export default observer(OrdersPrintAction);
