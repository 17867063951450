import { makeObservable, action, observable } from 'mobx';
import { CustomerGroupsStore } from './customerGroupsStore';
import { CustomerStore } from './customers/customerStore';
import { DeliveryRulesStore } from './deliveryRulesStore/deliveryRulesStore';
import { OrderStore } from './orders/orderStore';
import { SourceCategoriesStore } from './sourceCategories/sourceCategoriesStore';
import { ProductStore } from './products/productStore';
import { ProductGroupsStore } from './productGroupsStore';
import { DocumentTemplateStore } from './documentTemplatesStore';
import { UserStore } from './user/userStore';
import { StandingOrderStore } from './standingOrders/standingOrderStore';

export class RootStore {
  @observable user: any = [];
  @observable orders: OrderStore;
  @observable products: ProductStore;
  @observable currentUserStore: UserStore;
  @observable customers: CustomerStore;
  @observable standingOrders: StandingOrderStore;
  @observable deliveryRules: DeliveryRulesStore;
  @observable customerGroups: CustomerGroupsStore;
  @observable productGroups: ProductGroupsStore;
  @observable sourceCategories: SourceCategoriesStore;
  @observable documentTemplates: DocumentTemplateStore;

  constructor(user?: any) {
    this.user = user;
    this.currentUserStore = new UserStore();
    this.orders = new OrderStore(this.user);
    this.products = new ProductStore(this.user);
    this.customers = new CustomerStore(this, this.user);
    this.standingOrders = new StandingOrderStore(this, this.user);
    this.deliveryRules = new DeliveryRulesStore(this.user);
    this.customerGroups = new CustomerGroupsStore(this.user);
    this.productGroups = new ProductGroupsStore(this.user);
    this.sourceCategories = new SourceCategoriesStore(this.user);
    this.documentTemplates = new DocumentTemplateStore(this.user);
    makeObservable(this);
  }
}
