import { Thead, Th, Tr, TableHeadProps, Box } from '@chakra-ui/react';
import { observer } from 'mobx-react';

import OrdersDataGridHeaderCell from './OrdersDataGridHeaderCell';

const OrdersDataGridHeader = (props: TableHeadProps) => {
  return (
    <Thead {...props} borderBottom="1px solid" borderBottomColor="gray.300">
      <Tr>
        <Th width="1%" minWidth="58px">
          <Box py="3" px="6">
            &nbsp;
          </Box>
        </Th>
        <OrdersDataGridHeaderCell
          id="supplier"
          sortKey="suppliers.name"
          title="To"
          width="294px"
        />
        <OrdersDataGridHeaderCell
          id="orderedBy"
          sortKey={['users.surname', 'users.firstname']}
          title="Ordered by"
          width="294px"
        />
        <OrdersDataGridHeaderCell
          id="deliveryDate"
          sortKey="delivery_date"
          title="Delivery date"
          width="184px"
        />
        <OrdersDataGridHeaderCell
          id="date"
          sortKey="date"
          title="Time sent"
          width="224px"
        />
        <OrdersDataGridHeaderCell
          id="orderNumber"
          sortKey="order_number"
          title="Order #"
          width="124px"
        />
        <OrdersDataGridHeaderCell
          id="customerPO"
          sortKey="purchase_order_number"
          title="PO Number"
          width="184px"
        />
      </Tr>
    </Thead>
  );
};

export default observer(OrdersDataGridHeader);
