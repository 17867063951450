import { useFlags } from 'launchdarkly-react-client-sdk';
import slugify from 'slugify';
import {
  Divider,
  Flex,
  Heading,
  Image,
  VStack,
  FlexProps,
} from '@chakra-ui/react';

import {
  InboxIcon,
  BoxIcon,
  BoxGroupIcon,
  SettingsIcon,
  ExternalLinkIcon,
  CalendarIcon,
  UserGroupIcon,
  HelpIcon,
  MoneyIcon,
  RepeatIcon,
} from '../Icons/IconsNew';
import NavItem from '../NavItem/NavItem';
import useNavState from '../../hooks/useNavState';
import SidebarNavItem, { SidebarSubItem } from './SidebarNavItem';
import { UserNav } from '../Nav/UserNav';
import { useCurrentUser } from '../../hooks/useStores';

interface SidebarProps extends FlexProps {}

const baseSupplierNavItems: SidebarNavItem[] = [
  {
    label: 'Orders',
    link: '/orders',
    icon: InboxIcon,
  },
  {
    label: 'Standing Orders',
    link: '/standing-orders',
    icon: RepeatIcon,
  },
  {
    label: 'Products',
    link: '/products',
    icon: BoxIcon,
  },
  {
    label: 'Customers',
    link: '/customers',
    icon: UserGroupIcon,
  },
  {
    id: 'settings',
    label: 'Settings',
    icon: SettingsIcon,
    subitems: [
      {
        label: 'Integrations',
        link: '/settings/integrations',
        icon: ExternalLinkIcon,
      },
      {
        label: 'Delivery days',
        link: '/settings/delivery-days',
        icon: CalendarIcon,
      },
      {
        label: 'Customer groups',
        link: '/customer-group-sets',
        icon: UserGroupIcon,
      },
      {
        label: 'Source categories',
        link: '/source-categories',
        icon: BoxIcon,
      },
      {
        label: 'Pricing tiers',
        link: '/pricing-tiers',
        icon: MoneyIcon,
      },
      {
        label: 'Product groups',
        link: '/product-group-sets',
        icon: BoxGroupIcon,
      },
    ],
  },
];

const buyerNavItems: SidebarNavItem[] = [
  {
    label: 'Orders',
    link: '/orders',
    icon: InboxIcon,
  },
  {
    label: 'Standing Orders',
    link: '/standing-orders',
    icon: RepeatIcon,
  },
];

const Sidebar = (props: SidebarProps) => {
  const { isBuyer } = useCurrentUser();

  const [open, selected, setSelected, resetSelected] = useNavState();

  const supplierNavItems = baseSupplierNavItems.map((item) =>
    item.id === 'settings'
      ? {
          ...item,
          subitems: [...(item.subitems || [])],
        }
      : item,
  );

  const navItems = isBuyer ? buyerNavItems : supplierNavItems;

  const imageName = '/favicon-xl.png';
  return (
    <Flex
      direction="column"
      alignItems="center"
      backgroundColor="gray.200"
      boxShadow="md"
      position="relative"
      overflowY="auto"
      {...props}
    >
      <Heading as="h1" my="44px">
        <Image w="40px" src={imageName} alt="HospoConnect" />
      </Heading>
      <Divider
        ml="20px"
        mr="20px"
        width="calc(100% - 40px)"
        borderColor="gray.300"
        borderBottomWidth="2px"
      />
      <Flex
        direction="column"
        justifyContent="space-between"
        flex="1"
        width="100%"
      >
        <VStack width="100%" spacing="5" mt="29px">
          {navItems.map((item) => (
            <SidebarNavItem
              id={item.id || slugify(item.label, { lower: true })}
              key={item.id || slugify(item.label, { lower: true })}
              label={item.label}
              link={item.link}
              icon={
                item.icon ? <item.icon width="32px" height="32px" /> : undefined
              }
              openNav={open}
              selectedNav={selected}
              setSelected={setSelected}
              resetSelected={resetSelected}
            >
              {item.subitems?.map((subitem) => (
                <SidebarSubItem
                  key={subitem.id || slugify(subitem.label)}
                  {...subitem}
                />
              ))}
            </SidebarNavItem>
          ))}
        </VStack>

        <VStack my={2}>
          <NavItem
            label="Help"
            fontSize="13px"
            icon={<HelpIcon width="24px" height="24px" />}
            link={`https://www.hospoconnect.co.nz/help?${
              isBuyer ? 'tab=buyers' : 'tab=suppliers'
            }`}
            target="_blank"
          />
          <UserNav />
        </VStack>
      </Flex>
    </Flex>
  );
};

export default Sidebar;
