import { Tr, Td, HStack, Checkbox } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import useRealtimeData, {
  RealtimeDataEventPayloadType,
} from '../../../hooks/useRealtimeData';
import { useOrders } from '../../../hooks/useStores';
import { shouldPreventOnClick } from '../../../utils';
import StatusDot from '../../../components/Orders/StatusDot';
import Tag from '../../../components/Tag/Tag';
import DateText from '../../../components/Orders/DateText';
import { RefObject } from 'react';
import DataGridCell from './OrdersDataGridCell';
import SuppliersOrdersDataGridHeader from './SuppliersOrdersDataGridHeader';
import { Order } from '../../../stores/orders/order';
import OrdersDataGrid from './OrdersDataGrid';
import SuppliersOrdersDataGridRow from './SuppliersOrdersDataGridRow';

const SupplierOrdersDataGrid = ({
  dataGridRef,
}: {
  dataGridRef: RefObject<HTMLTableElement>;
}) => {
  const {
    orders,
    ordersAreLoading,
    currentStatusFilter,
    getOrders,
    totalCount,
    clearFilters,
    uncheckOrdersIfHidden,
  } = useOrders();

  const onReceived = (payload: RealtimeDataEventPayloadType) => {
    if (payload.entityType == 'order') {
      if (currentStatusFilter != 'new' && payload.eventType == 'create') {
        /** Don't bother refreshing for a create event unless we are on the new tab */
        return;
      }

      getOrders(
        () => {
          uncheckOrdersIfHidden(payload.entityIds);
        },
        () => {},
      );
    }
  };
  const onTabReactivate = () => {
    getOrders(
      () => {},
      () => {},
    );
  };
  useRealtimeData(
    'DataFeedChannel',
    onReceived,
    [currentStatusFilter],
    onTabReactivate,
  );

  return (
    <OrdersDataGrid
      dataGridRef={dataGridRef}
      orders={orders}
      header={<SuppliersOrdersDataGridHeader />}
      isLoading={ordersAreLoading}
      totalCount={totalCount}
      clearFilters={clearFilters}
      renderItem={(order) => (
        <SuppliersOrdersDataGridRow key={order.id} order={order} />
      )}
    />
  );
};

export default observer(SupplierOrdersDataGrid);
