import { Observer, observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import {
  Box,
  Input,
  FormLabel,
  FormControl,
  Text,
  HStack,
  Avatar,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button as ChakraButton,
  ButtonGroup,
  ModalCloseButton,
} from '@chakra-ui/react';
import { TimeIcon } from '@chakra-ui/icons';

import Order from '../../models/Order';
import { CaretLeftIcon, ShoppingCartIcon } from '../Icons/Icons';
import { useAddOrder } from '../../contexts/addOrder';
import OrderItem from '../../models/OrderItem';
import { pluralizeString } from '../../utils';
import isDeliveryDateAvailable from '../../utils/isDeliveryDateAvailable';
import DateInput, { PureDateInput } from '../DateInput/DateInput';
import useEditSnapshot from '../../hooks/useEditSnapshot';
import MaxLengthTextarea from '../MaxLengthTextarea/MaxLengthTextarea';
import { Button } from '../Button/Button';
import ReviewOrderItemRow from './ReviewOrderItemRow';
import OrderAddressField from './OrderAddressField';
import { useCurrentUser } from '../../hooks/useStores';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { AddressFieldComponents } from '../../models/Address';
import { newAddressValidationSchema } from './AddAddressForm';
import Banner from '../Banner/Banner';
import moment from 'moment';
import { WarningIcon } from '../Icons/IconsNew';
import { Caption } from '../Typography/Typography';
import { OrderFieldName } from './AddOrderModalContent';

const newAddressValues: AddressFieldComponents = {};

const ReviewOrderScreen = observer(
  ({
    onBack,
    onSubmit,
    isLoading,
    initialiseEditing = false,
    disabledFields = [],
  }: {
    onBack: () => void;
    onSubmit: () => void;
    isLoading: boolean;
    initialiseEditing: boolean;
    disabledFields?: OrderFieldName[];
  }) => {
    const { order } = useAddOrder();
    const { company, companyAddresses, isBuyer } = useCurrentUser();
    const [createAddress, setCreateAddress] = useState(false);
    const [errors, setErrors] = useState<any>({});

    const [
      editState,
      isEditing,
      onStartEditing,
      onSaveEditing,
      onCancelEditing,
    ] = useEditSnapshot<typeof Order>(order);

    useEffect(() => {
      if (initialiseEditing) {
        onStartEditing();
      }
    }, []);

    const validate = () => {
      let newErrors: any = {};

      // Require items
      if (
        order.items.filter((item) => item.amount !== 0 && item.amount !== null)
          .length == 0
      ) {
        newErrors.items = 'Please add at least one item';
      }

      // Require delivery date
      if (!order.deliveryDate) {
        newErrors.deliveryDate = 'Please select a delivery date';
      }

      // Require order items to be valid
      order.items.forEach((item) => {
        item.validate();
        if (item.isValid === false) {
          newErrors.items = 'Please check your items are valid';
        }
      });

      setErrors(newErrors);

      return Object.keys(newErrors).length === 0;
    };

    const clearError = (key: string) => {
      const newErrors = { ...errors };
      delete newErrors[key];
      setErrors(newErrors);
    };

    const handleAddNewAddress = (newAddress: AddressFieldComponents) => {
      if (company) {
        return company.createAddress(newAddress).then((data) => {
          if (data.id) {
            const addressFields = {
              id: data.id,
              full_address: data.full_address || '',
              instructions: data.instructions || '',
            };
            order.setAddress(addressFields);
            editState.setAddress(addressFields);
          }
        });
      }
      return Promise.resolve();
    };

    const handleSubmitWithAddress = (address: AddressFieldComponents) => {
      handleAddNewAddress(address).then(() => {
        onSubmit();
      });
    };

    const leadTime = Math.max(
      ...order.items.map((item: Instance<typeof OrderItem>) => {
        return item.buyable.product.lead_time_hours || 0;
      }),
    );

    const dateIsAvailable =
      !isBuyer ||
      !order.deliveryDate ||
      isDeliveryDateAvailable(
        order.deliveryDate,
        moment(),
        order.supplier?.delivery_rules?.toJSON(),
        !!order.supplier?.delivery_rules_enabled,
        leadTime,
      );

    return (
      <Formik
        initialValues={newAddressValues}
        onSubmit={(val) => handleSubmitWithAddress(val)}
        validationSchema={newAddressValidationSchema}
      >
        {(newAddressFormik) => {
          const isAddressValid =
            !createAddress ||
            (newAddressFormik.dirty && newAddressFormik.isValid);

          return (
            <Observer>
              {() => (
                <ModalContent>
                  <ModalHeader
                    px={6}
                    pb={0}
                    bg="gray.100"
                    borderTopRadius="var(--chakra-radii-md)"
                    boxShadow="inset 0px -1px 0px #D1D5DB"
                  >
                    <ChakraButton
                      onClick={onBack}
                      variant="ghost"
                      leftIcon={<CaretLeftIcon />}
                      mb="6"
                      pl="0"
                    >
                      Back
                    </ChakraButton>
                    <ModalCloseButton top={4} right={4} />
                    <HStack mb="10">
                      <Avatar
                        size="lg"
                        src={
                          isBuyer ? order.supplier?.logo : order.customer?.logo
                        }
                      />
                      <Text>
                        {isBuyer ? order.supplier?.name : order.customer?.name}
                      </Text>
                    </HStack>
                  </ModalHeader>
                  <ModalBody p={0}>
                    <Box px="6" mt="6" mb="8">
                      {isBuyer ? (
                        <>
                          <PureDateInput
                            id="delivery_date"
                            label="Delivery date"
                            mb={2}
                            labelProps={{
                              fontSize: 'lg',
                            }}
                            value={order.deliveryDate}
                            onChange={(date) => {
                              clearError('deliveryDate');
                              order.setDeliveryDate(date);
                              editState.setDeliveryDate(date);
                            }}
                            deliveryRules={order.supplier?.delivery_rules?.toJSON()}
                            deliveryRulesEnabled={
                              order.supplier?.delivery_rules_enabled
                            }
                            isDisabled={disabledFields.includes('deliveryDate')}
                            leadTime={leadTime}
                            enforceCutoffTimes={true}
                          />
                        </>
                      ) : (
                        <DateInput
                          id="delivery_date"
                          label="Delivery date"
                          mb={2}
                          labelProps={{
                            fontSize: 'lg',
                          }}
                          value={order.deliveryDate}
                          onChange={(date) => {
                            clearError('deliveryDate');
                            order.setDeliveryDate(date);
                            editState.setDeliveryDate(date);
                          }}
                          allowPastDates={true}
                          isDisabled={disabledFields.includes('deliveryDate')}
                        />
                      )}
                      {errors.deliveryDate && (
                        <Text color="red.500" fontSize="sm">
                          {errors.deliveryDate}
                        </Text>
                      )}
                      {!dateIsAvailable && (
                        <Banner
                          status="error"
                          icon={
                            <WarningIcon color="error.amber" width="24px" />
                          }
                          title={`${moment(order.deliveryDate).format(
                            'dddd',
                          )}'s cut-off time has passed`}
                          description="Please note that the cut-off time for the delivery date you’ve requested has already passed. We will fulfil your order as soon as possible."
                          variant="warning"
                        />
                      )}
                      {leadTime > 0 && (
                        <HStack spacing="1">
                          <TimeIcon color="error.amber" width="24px" />
                          <Text color="error.amber" fontWeight={500}>
                            Some products in this order require a lead time of{' '}
                            {leadTime} hours.
                          </Text>
                        </HStack>
                      )}
                    </Box>

                    <FormControl id="delivery_address" px={6} mb={8}>
                      <FormLabel>
                        <Text fontSize="lg">Delivery Address</Text>
                      </FormLabel>
                      {isBuyer ? (
                        <OrderAddressField
                          value={`${order.delivery_address_id}`}
                          addresses={companyAddresses}
                          onSelectAddress={(address) => {
                            const addressFields = {
                              id: address.id,
                              full_address: address.full_address || '',
                              instructions: address.instructions || '',
                            };
                            order.setAddress(addressFields);
                            editState.setAddress(addressFields);
                          }}
                          onAddresssFormOpen={() => setCreateAddress(true)}
                          onAddresssFormClose={() => setCreateAddress(false)}
                        />
                      ) : (
                        <>
                          <Input
                            placeholder="123 Streety Street, Streetsville, Citytown, 1234"
                            value={order.delivery_address || ''}
                            onChange={(e) => {
                              const addressFields = {
                                id: null,
                                full_address: e.target.value || '',
                                instructions: '',
                              };
                              order.setAddress(addressFields);
                              editState.setAddress(addressFields);
                            }}
                          />
                          {order.delivery_instructions && (
                            <Caption color="gray.500">
                              Instructions: {order.delivery_instructions}
                            </Caption>
                          )}
                        </>
                      )}
                    </FormControl>

                    <FormControl id="purchase_order_number" px={6} mb={8}>
                      <FormLabel>
                        <Text fontSize="lg">Purchase order number</Text>
                      </FormLabel>
                      <Input
                        placeholder="Optional"
                        value={order.purchase_order_number || ''}
                        onChange={(e) => {
                          order.setPurchaseOrderNumber(e.target.value);
                          editState.setPurchaseOrderNumber(e.target.value);
                        }}
                      />
                    </FormControl>

                    <MaxLengthTextarea
                      id="delivery_message"
                      label="Message"
                      placeholder="Optional"
                      maxLength={500}
                      value={order.message || ''}
                      onChange={(value: string) => {
                        order.setMessage(value);
                        editState.setMessage(value);
                      }}
                      px="6"
                      mb="6"
                    />

                    <HStack
                      px={6}
                      py={2}
                      justify="space-between"
                      boxShadow="inset 0px -1px 0px #E5E7EB"
                    >
                      <Text fontSize="lg">Order</Text>
                      {isEditing ? (
                        <ButtonGroup>
                          <ChakraButton
                            variant="link"
                            colorScheme="green"
                            color="green.700"
                            onClick={onSaveEditing}
                          >
                            Save
                          </ChakraButton>
                          <ChakraButton
                            variant="link"
                            colorScheme="green"
                            color="green.700"
                            onClick={() => {
                              editState.items.forEach((item, key) => {
                                if (item.notes) {
                                  order.items[key].setNotes(item.notes);
                                }
                              });
                              onCancelEditing();
                            }}
                          >
                            Cancel
                          </ChakraButton>
                        </ButtonGroup>
                      ) : (
                        <ChakraButton
                          variant="link"
                          colorScheme="green"
                          color="green.700"
                          onClick={onStartEditing}
                        >
                          Edit
                        </ChakraButton>
                      )}
                    </HStack>
                    <ReviewItems
                      editState={editState}
                      isEditing={isEditing}
                      onBack={onBack}
                    />

                    {errors.items && (
                      <Box px="6" mt="6" mb="8">
                        <Text color="red.500" fontSize="sm">
                          {errors.items}
                        </Text>
                      </Box>
                    )}
                  </ModalBody>
                  <ModalFooter
                    backgroundColor="gray.100"
                    borderRadius="0 0 8px 8px"
                    justifyContent="space-between"
                  >
                    <HStack>
                      <ShoppingCartIcon color="gray.400" />
                      <Text color="gray.500">
                        {order.items.length}{' '}
                        {pluralizeString('item', order.items.length)}
                      </Text>
                    </HStack>
                    <Button
                      variant="primary"
                      isDisabled={!isAddressValid || isEditing}
                      isLoading={isLoading}
                      onClick={() => {
                        if (validate()) {
                          createAddress
                            ? newAddressFormik.submitForm()
                            : onSubmit();
                        }
                      }}
                    >
                      Complete order
                    </Button>
                  </ModalFooter>
                </ModalContent>
              )}
            </Observer>
          );
        }}
      </Formik>
    );
  },
);

const ReviewItems = observer(
  ({
    editState,
    isEditing = false,
    onBack,
  }: {
    editState: Instance<typeof Order>;
    isEditing?: boolean;
    onBack: () => void;
  }) => (
    <>
      {editState.items.length > 0 ? (
        editState.items.map((item: Instance<typeof OrderItem>) => (
          <ReviewOrderItemRow
            key={item.buyable.product.id}
            orderItem={item}
            count={editState}
            isEditing={isEditing}
            remove={editState.removeOrderItem}
          />
        ))
      ) : (
        <HStack
          justify="space-between"
          boxShadow="inset 0px -1px 0px #E5E7EB"
          px={6}
          py={3}
        >
          <Text>No products added</Text>
          {!isEditing && (
            <ChakraButton onClick={onBack}>
              Go back to add products
            </ChakraButton>
          )}
        </HStack>
      )}
    </>
  ),
);

export default ReviewOrderScreen;
