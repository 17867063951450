import {
  Th,
  Box,
  HStack,
  Text,
  TableColumnHeaderProps,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { isUndefined } from 'lodash';

import { useOrders } from '../../../hooks/useStores';
import { CaretDownIcon, CaretUpIcon } from '../../../components/Icons/IconsNew';

interface OrderDataGridHeaderCellProps extends TableColumnHeaderProps {
  id: string;
  sortKey: string | string[];
  title: string;
}

const OrdersDataGridHeaderCell = observer(
  ({ id, sortKey, title, ...rest }: OrderDataGridHeaderCellProps) => {
    const {
      currentSortKeys,
      currentSortDirection,
      setCurrentSortKeys,
      setCurrentSortDirection,
      columnPreferences,
    } = useOrders();
    const isCurrent = id in currentSortKeys;
    const columnPreference = !isUndefined(columnPreferences)
      ? columnPreferences[id]
      : undefined;
    const isHidden = columnPreference?.isHidden;
    if (isHidden) return <></>;
    return (
      <Th
        onClick={() => {
          const direction =
            isCurrent && currentSortDirection === 'desc' ? 'asc' : 'desc';
          setCurrentSortKeys(id, sortKey);
          setCurrentSortDirection(direction);
        }}
        {...rest}
      >
        <Box
          bg={isCurrent ? 'gray.200' : ''}
          py="3"
          px="6"
          cursor="pointer"
          borderRadius="8px 8px 0px 0px"
        >
          <HStack spacing="3px">
            <Text>{title}</Text>
            {isCurrent &&
              (currentSortDirection === 'asc' ? (
                <CaretUpIcon width="12px" />
              ) : (
                <CaretDownIcon width="12px" />
              ))}
          </HStack>
        </Box>
      </Th>
    );
  },
);

export default OrdersDataGridHeaderCell;
