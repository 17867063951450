import React from 'react';
import { RootContext } from '../contexts/store';

export const useStore = () => React.useContext(RootContext);

export const useCurrentUser = () => {
  const { currentUserStore } = useStore();
  return currentUserStore;
};

export const useOrders = () => {
  const { orders } = useStore();
  return orders;
};
export const useProducts = () => {
  const { products } = useStore();
  return products;
};
export const useCustomers = () => {
  const { customers } = useStore();
  return customers;
};
export const useStandingOrders = () => {
  const { standingOrders } = useStore();
  return standingOrders;
};

export const useDeliveryRules = () => {
  const { deliveryRules } = useStore();
  return deliveryRules;
};

export const useCustomerGroups = () => {
  const { customerGroups } = useStore();
  return customerGroups;
};

export const useProductGroups = () => {
  const { productGroups } = useStore();
  return productGroups;
};

export const useSourceCategories = () => {
  const { sourceCategories } = useStore();
  return sourceCategories;
};

export const useDocumentTemplates = () => {
  const { documentTemplates } = useStore();
  return documentTemplates;
};
