import { observer } from 'mobx-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isTablet } from 'react-device-detect';
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Tooltip,
  MenuButtonProps,
  forwardRef,
} from '@chakra-ui/react';
import FakeProgressModal from '../../components/FakeProgressModal/FakeProgressModal';
import OrdersUpdateStatusModal from './OrdersUpdateStatusModal';
import { pluralizeString } from '../../utils';
import { DownloadIcon } from '../../components/Icons/IconsNew';
import useDocuments from '../../hooks/useDocuments';
import SelectProductModal from '../../components/SelectProductModal/SelectProductModal';

export type OrdersDownloadActionProps = {
  orderIds: number[];
  buttonText?: string | null;
  tooltipText: string;
  useIconButton: boolean;
};

const OrdersDownloadAction = ({
  orderIds,
  buttonText,
  tooltipText,
  useIconButton,
}: OrdersDownloadActionProps): JSX.Element => {
  const downloadBase64 = async (response: any) => {
    setProcessingIsDone(true);

    /** First, get the filename from the disposition header
     *
     * Content-Disposition: attachment; filename="PickSheet.pdf"
     */
    const contentDispositionHeader = response.headers.get(
      'Content-Disposition',
    );
    const mimeType = response.headers.get('Content-Type');
    const filename = contentDispositionHeader
      .split(';')
      .find(
        (part: string) =>
          part.trim().substring(0, part.trim().indexOf('=')) == 'filename',
      )
      .split('=')[1]
      .replaceAll('"', '');

    const data = await response.text();
    const linkEl = document.createElement('a');
    const linkSource = `data:${mimeType};base64,${data}`;
    linkEl.href = linkSource;
    linkEl.download = filename;
    document.body.append(linkEl);
    linkEl.click();
    linkEl.remove();

    afterProcessing();
  };

  const {
    showLegacyPickSheet,
    showProductsReport,
    modalIsOpen,
    modalText,
    updateStatusModalIsOpen,
    setUpdateStatusModalIsOpen,
    timePerItemMs,
    processingIsDone,
    setProcessingIsDone,
    newOrderIds,
    resetState,
    onClickDocument,
    selectProductModalIsOpen,
    setSelectProductModalIsOpen,
    onSelectProduct,
    selectedProductId,
    onConfirmProduct,
    afterProcessing,
    documentTemplates,
    onClickDocumentTemplate,
  } = useDocuments('Download', 'download', orderIds, downloadBase64);
  const flags = useFlags();
  const showOrderSummaryReport = !!flags.orderSummaryReport;
  const isSingular = orderIds.length === 1;

  const ariaLabel = 'Open Download menu';
  const MenuButtonComponent = forwardRef<MenuButtonProps, 'div'>(
    (props: MenuButtonProps, ref) => {
      if (useIconButton) {
        return (
          <MenuButton
            as={IconButton}
            aria-label={ariaLabel}
            icon={<DownloadIcon width="24px" />}
            variant="ghost"
            ref={ref}
          />
        );
      } else {
        return (
          <MenuButton
            as={Button}
            aria-label={ariaLabel}
            leftIcon={<DownloadIcon width="24px" />}
            variant="ghost"
            ref={ref}
          >
            {buttonText}
          </MenuButton>
        );
      }
    },
  );

  if (isTablet) {
    return <></>;
  }

  return (
    <>
      <Menu>
        <Tooltip
          label={tooltipText}
          isDisabled={modalIsOpen} // This fixes a bug where the tooltip stays open over top of the modal
        >
          <MenuButtonComponent />
        </Tooltip>
        <MenuList zIndex="dropdown">
          {documentTemplates.map((documentTemplate) => (
            <MenuItem
              key={documentTemplate.id}
              onClick={() => {
                onClickDocumentTemplate(documentTemplate);
              }}
            >
              {documentTemplate.name}
            </MenuItem>
          ))}
          {documentTemplates.length > 0 && <MenuDivider />}
          <MenuItem
            onClick={() => {
              onClickDocument('pick_sheet');
            }}
          >
            {pluralizeString('Pick sheet', orderIds.length)}
          </MenuItem>
          <MenuItem
            isDisabled={isSingular}
            onClick={() => {
              onClickDocument('pick_all_sheet');
            }}
          >
            Pick-all sheet
          </MenuItem>
          <MenuItem
            isDisabled={isSingular}
            onClick={() => {
              onClickDocument('production_sheet');
            }}
          >
            Production sheet
          </MenuItem>
          <MenuItem
            onClick={() => {
              onClickDocument('packing_slip');
            }}
          >
            {pluralizeString('Packing slip', orderIds.length)}
          </MenuItem>
          <MenuItem
            isDisabled={isSingular}
            onClick={() => {
              onClickDocument('quantity_report');
            }}
          >
            Quantity report
          </MenuItem>
          {showLegacyPickSheet && (
            <MenuItem
              onClick={() => {
                onClickDocument('legacy_pick_sheet');
              }}
            >
              {pluralizeString('Legacy Pick sheet', orderIds.length)}
            </MenuItem>
          )}
          {showOrderSummaryReport && (
            <MenuItem
              isDisabled={isSingular}
              onClick={() => {
                onClickDocument('order_summary_report');
              }}
            >
              Order Summary report
            </MenuItem>
          )}
          {showProductsReport && (
            <MenuItem
              isDisabled={isSingular}
              onClick={() => {
                onClickDocument('products_report');
              }}
            >
              Products report
            </MenuItem>
          )}
          <MenuItem
            isDisabled={isSingular}
            onClick={() => {
              onClickDocument('sales_report');
            }}
          >
            Sales report
          </MenuItem>
        </MenuList>
      </Menu>

      <FakeProgressModal
        mainText={modalText}
        isOpen={modalIsOpen}
        isDone={processingIsDone}
        totalItems={orderIds.length}
        timePerItemMs={timePerItemMs}
      />
      <OrdersUpdateStatusModal
        orderIds={newOrderIds}
        isOpen={updateStatusModalIsOpen}
        status={'processing'}
        onClose={() => {
          resetState();
          setUpdateStatusModalIsOpen(false);
        }}
      />
      <SelectProductModal
        isOpen={selectProductModalIsOpen}
        onClose={() => setSelectProductModalIsOpen(false)}
        onSelect={onSelectProduct}
        onConfirm={onConfirmProduct}
        selectedProductId={selectedProductId}
        confirmButtonText={'Create Report'}
      />
    </>
  );
};

export default observer(OrdersDownloadAction);
