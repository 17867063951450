import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useAuth } from '../../../contexts/auth';
import useAPI from '../../../hooks/useAPI';
import { useOrders } from '../../../hooks/useStores';
import { useAccounting } from '../../../contexts/accounting';
import MatchContactsModal from '../../../components/MatchContactsModal/MatchContactsModal';
import ProcessingModal from '../../../components/ProcessingModal/ProcessingModal';
import { useHistory } from 'react-router-dom';
import { useErrorToast, useSuccessToast } from '../../../components/toast';
import { ExportIcon, TickInCircleIcon } from '../../../components/Icons/Icons';
import AccountingErrorModal from '../../../components/AccountingErrorModal/AccountingErrorModal';

import {
  Button,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
} from '@chakra-ui/react';

interface ErrorItem {
  error_key: string;
  error_messages: string[] | null;
  error_raw: string | null;
}

const OrderExportInvoiceAction = () => {
  const { user } = useAuth();
  const {
    currentAccountingConnection,
    exportInvoice,
    getCurrentAccountingConnection,
  } = useAccounting();
  const { currentOrder, setCurrentOrder } = useOrders();
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [matchContactModal, setMatchContactModal] = useState(false);
  const [connectionStaleModal, setConnectionStaleModal] = useState(false);
  const [connectionErrorModal, setConnectionErrorModal] = useState(false);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [errorItem, setErrorItem] = useState<ErrorItem | null>(null);
  const order = currentOrder;

  let history = useHistory();

  useEffect(() => {
    getCurrentAccountingConnection(() => {});
  }, []);

  const [getOrder] = useAPI({
    method: 'GET',
  });

  const isAccountingConnectionActive =
    currentAccountingConnection &&
    currentAccountingConnection.connection_status == 'active';

  const accountingConnectorName =
    currentAccountingConnection && currentAccountingConnection.label;

  const accountingInvoice = order.accounting_invoice;

  function sendToAccountingConnector() {
    setButtonLoading(true);

    if (isAccountingConnectionActive) {
      if (!currentAccountingConnection.is_connection_stale) {
        setButtonLoading(true);
        exportInvoice(
          '/v4/accounting_connectors/invoices',
          user,
          order.id,
          (data) => {
            if (currentAccountingConnection.key === 'infusion') {
              initPolling();
            } else {
              handleSuccess(data);
            }
          },
          (data) => {
            console.log(data);
            // Response is { error_key: 'error_key', error_messages: ['error message'], error_raw: 'Some error' }
            handleError(data);
          },
        );
      } else {
        setConnectionStaleModal(true);
        setButtonLoading(false);
      }
    } else {
      setConnectionErrorModal(true);
      setButtonLoading(false);
    }
  }

  function initPolling() {
    // Poll for the updated data every 5 seconds.
    const pollingInterval = setInterval(() => {
      getOrder(`/v4/orders/${currentOrder.id}`).then((data: any) => {
        if (data.accounting_invoice) {
          if (data.accounting_invoice.status === 'complete') {
            clearInterval(pollingInterval);
            handleSuccess(data);
          } else if (data.accounting_invoice.status === 'error') {
            clearInterval(pollingInterval);
            handleError(data.accounting_invoice);
          }
        }
      });
    }, 5000);
  }

  function handleError(errorItem: ErrorItem) {
    if (errorItem.error_key === 'fetch_contact_failed') {
      errorToast({
        title: 'Contact not found',
        description: `Please match ${currentOrder.customer_company.name} to a ${accountingConnectorName} contact again.`,
      });
    }
    if (
      errorItem.error_key === 'contact_not_mapped' ||
      errorItem.error_key === 'fetch_contact_failed'
    ) {
      setMatchContactModal(true);
    } else {
      setErrorItem(errorItem);
      setErrorModalIsOpen(true);
    }

    setButtonLoading(false);
    errorToast({
      description: `Order failed to be sent to ${accountingConnectorName}.`,
    });
  }

  function handleSuccess(data: any) {
    setCurrentOrder(data);
    setButtonLoading(false);
    successToast({
      description: `Order successfully sent to ${accountingConnectorName}.`,
    });
  }

  function buttonText() {
    if (accountingInvoice && accountingInvoice.status === 'complete') {
      const label = () => {
        switch (accountingInvoice.connector_type) {
          case 'xero':
            return 'Xero';
          case 'myob':
            return 'MYOB';
          case 'infusion':
            return 'Infusion';
          case 'unleashed':
            return 'Unleashed';
          case 'accredo':
            return 'Accredo';
          case 'mountain_stream':
            return 'MountainStream';
          case 'cin7_core':
            return 'Cin7 Core';
          default:
            return 'Integration';
        }
      };
      if (accountingInvoice.connector_type === 'xero') {
        return `View in ${label()}`;
      } else {
        return `Sent to ${label()}`;
      }
    } else {
      return `Send to ${accountingConnectorName}`;
    }
  }

  if (order && currentAccountingConnection) {
    return (
      <>
        <Button
          onClick={(e) => {
            if (accountingInvoice && accountingInvoice.status !== 'error') {
              if (accountingInvoice.connector_url) {
                window.open(accountingInvoice.connector_url, '_blank');
              } else {
                e.preventDefault();
              }
            } else {
              sendToAccountingConnector();
            }
          }}
          color={
            accountingInvoice && accountingInvoice.status === 'complete'
              ? 'green.600'
              : 'default'
          }
          isDisabled={
            accountingInvoice &&
            accountingInvoice.connector_type === 'infusion' &&
            accountingInvoice.status === 'complete'
          }
          isLoading={
            buttonLoading ||
            (accountingInvoice && accountingInvoice.status === 'pending')
          }
          leftIcon={
            accountingInvoice && accountingInvoice.status === 'complete' ? (
              <TickInCircleIcon />
            ) : (
              <ExportIcon />
            )
          }
        >
          {buttonText()}
        </Button>

        <MatchContactsModal
          isOpen={matchContactModal}
          onClose={() => {
            setMatchContactModal(false);
          }}
          onSuccess={() => {
            successToast({
              description: `Contact mapped. Sending Order to ${accountingConnectorName}.`,
            });
            sendToAccountingConnector();
          }}
          contactId={order.customer_company.id}
          contactName={order.customer_company.name}
        />

        <ProcessingModal isOpen={buttonLoading}>
          <Text>Sending to {accountingConnectorName}.</Text>
        </ProcessingModal>

        {errorItem && (
          <AccountingErrorModal
            accountingConnectorName={accountingConnectorName}
            errorItem={errorItem}
            isOpen={errorModalIsOpen}
            onClose={() => setErrorModalIsOpen(false)}
          />
        )}

        <Modal
          isOpen={connectionStaleModal}
          onClose={() => setConnectionStaleModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Integration is offline</ModalHeader>
            <ModalBody>
              We couldn't connect to your integration. Check it's running, then
              refresh this page and try again.
            </ModalBody>

            <ModalFooter>
              <ButtonGroup>
                <Button
                  variant="primary"
                  onClick={() => setConnectionStaleModal(false)}
                >
                  Ok
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={connectionErrorModal}
          onClose={() => setConnectionErrorModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              We couldn't connect to {accountingConnectorName}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Try reconnecting to your {accountingConnectorName} account in
              Settings, then try again.
            </ModalBody>

            <ModalFooter>
              <ButtonGroup>
                <Button
                  variant="primary"
                  onClick={() => history.push('/settings/integrations')}
                >
                  Integration Settings
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  } else {
    return <></>;
  }
};

export default observer(OrderExportInvoiceAction);
