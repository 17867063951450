import {
  HStack,
  Flex,
  Text,
  IconButton,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  Button,
  MenuItemOption,
  Table,
  Divider,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useCurrentUser, useOrders } from '../../../hooks/useStores';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EditBoxIcon,
  SettingsIcon,
} from '../../../components/Icons/IconsNew';
import { Subhead } from '../../../components/Typography/Typography';
import OrdersListActions from '../List/OrdersListActions';
import HiddenScrollBox from '../../../components/HiddenScrollBox/HiddenScrollBox';
import OrdersPageActionBar from '../OrdersPageActionBar';
import BuyersOrdersDataGridHeader from './BuyersOrdersDataGridHeader';
import SuppliersOrdersDataGridHeader from './SuppliersOrdersDataGridHeader';

const OrdersDataGridActions = ({ errorContainerRef }: any) => {
  const {
    pageLimit,
    setCurrentPage,
    totalCount,
    totalPages,
    currentPage,
    allColumns,
    visibleColumns,
    setColumnPreference,
  } = useOrders();
  const { isBuyer } = useCurrentUser();

  const startCount = (currentPage - 1) * pageLimit + 1;
  let endCount = currentPage * pageLimit;
  if (endCount > totalCount) endCount = totalCount;

  return (
    <Flex flexDirection="column" flex="1" overflowY="auto" maxHeight="50vh">
      <OrdersPageActionBar errorContainerRef={errorContainerRef} />
      <Box ref={errorContainerRef} bg="gray.200" pl="24px" pr="16px" />
      <Flex w="full" justify="space-between" pr="16px">
        <HStack
          spacing="6"
          justify="space-between"
          py="2"
          pr="2"
          minHeight="56px"
          color="gray"
        >
          {!isBuyer && (
            <OrdersListActions errorContainerRef={errorContainerRef} />
          )}
        </HStack>

        <HStack>
          <Menu closeOnSelect={false}>
            <MenuButton
              as={Button}
              size="xs"
              leftIcon={<EditBoxIcon width="16px" />}
              variant="secondary"
              colorScheme="gray"
              mr="4"
            >
              Edit columns
            </MenuButton>
            <MenuList zIndex="dropdown">
              <MenuOptionGroup
                title="Columns"
                type="checkbox"
                defaultValue={visibleColumns}
                onChange={(e) => {
                  const checkedColumns = e as string[];
                  allColumns.forEach((column) => {
                    if (checkedColumns.includes(column)) {
                      setColumnPreference(column, false);
                    } else {
                      setColumnPreference(column, true);
                    }
                  });
                }}
              >
                <MenuItemOption value="customer" defaultChecked isDisabled>
                  {isBuyer ? 'To' : 'Customer'}
                </MenuItemOption>
                <MenuItemOption value="orderedBy">Ordered by</MenuItemOption>
                <MenuItemOption value="deliveryDate">
                  Delivery date
                </MenuItemOption>
                <MenuItemOption value="date" defaultChecked isDisabled>
                  Time sent
                </MenuItemOption>
                <MenuItemOption value="orderNumber">Order #</MenuItemOption>
                <MenuItemOption value="customerPO">PO Number</MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
          <Divider
            orientation="vertical"
            height="24px"
            color="gray.300"
            border="1px"
          />
          <Subhead>
            <Text as="strong" fontWeight="600" ml="4">
              {startCount}-{endCount}
            </Text>{' '}
            of {totalCount.toLocaleString('en-NZ')}
          </Subhead>
          <IconButton
            aria-label="Previous Page"
            onClick={() => {
              if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
              }
            }}
            isDisabled={currentPage <= 1}
            icon={<ChevronLeftIcon width="24px" />}
          />
          <IconButton
            aria-label="Next Page"
            onClick={() => {
              if (totalPages > currentPage) {
                setCurrentPage(currentPage + 1);
              }
            }}
            isDisabled={totalPages <= currentPage}
            icon={<ChevronRightIcon width="24px" />}
          />
        </HStack>
      </Flex>
    </Flex>
  );
};

export default observer(OrdersDataGridActions);
